.gaugeItem {
  width: calc(98% / 2);
  margin-right: 2%;
  margin-bottom: 2%;
}

.gaugeItem:nth-child(2n + 0) {
  margin-right: 0%;
}

.svgBox {
  display: block;
  width: 160px;
  /* height: 160px; */
  margin: 3px;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.svgBox circle {
  fill: none;
  stroke-width: 12px;
  margin: 3px;
  transform-origin: 75px 70px 0;
  transform: rotate(270deg);
  animation-delay: 1s;
  animation: fadeIn 0.75s linear forwards;
}

.svgBox circle:nth-child(3) {
  stroke: rgba(80, 70, 70, 0.2);
}

.svgBox circle:nth-child(2) {
  stroke: #7fc146;
  stroke-dasharray: 402.124;
  stroke-dashoffset: 402.124;
  transition: all 2s ease-out;
  animation-delay: 1s;
  animation: fadeIn 0.75s linear forwards;
}

.svgBox circle:nth-child(2) {
  stroke: url(#MyGradient);
}

.svgBox text {
  fill: black;
}
.svgBoxText {
  font-size: 30px;
  font-weight: 300;
}
.svgBox text.caption {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}
