.ml-sm {
  margin-left: 6% !important;
}

.h4-margin {
  margin: 0.65em 0;
}

.ml-xs {
  margin-left: 2%;
}

.mr-md {
  margin-right: 30%;
}

hr {
  color: rgba(80, 70, 70, 0.2);
}

label {
  color: rgba(80, 70, 70, 1);
}

.mw-15 {
  min-width: 15px;
}

.mw-md {
  min-width: 4.2rem;
}

tr td {
  color: rgba(80, 70, 70, 1);
  font-size: 14px;
}

.bold {
  font-weight: bolder;
}

.dot1 {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
  position: relative;
  background-color: green;
  transform: scale(0.4);
}

.dot1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  animation: ripple 1.5s ease-out infinite;
  animation-delay: 1s;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(3);
  }
}
