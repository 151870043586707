.analyticsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gaugeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gaugeTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.progressLabel {
  width: 100%;
}

.label h5 {
  margin: 0;
}

.progressValue {
  width: 70%;
}

.parameterValue {
  color: grey;
  margin: 0;
}
